import { useState, useRef } from "react";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";

import { content } from "../utils/helpers";
import LoginForm from "../features/LoginForm";
import { NavLink } from "react-router-dom";
import { size } from "../utils/helpers";
import publicLogo from "../assets/public-logo.png";
import { useNavigate } from "react-router-dom";
import { urlPaths } from "../utils/config";
import mobileMascot from "../assets/mobile-message-login.jpg";

const LoginPage = () => {
    const sitekey = "6Lf_8-wnAAAAAKg2EjPlV_lC4b4Vn6cJ6H0nXT8I";
    const recaptcha = useRef();
    const [show, setShow] = useState(true);
    const navigate = useNavigate();

    return (
        <LoginContainer>
            <Wrapper>
                <img src={publicLogo} alt="Wellcome to Commenter.ai" onClick={() => { navigate(urlPaths.homenew) }} />
                <Title>{content.PublicPages.Login.Title}</Title>
                <Content>{content.PublicPages.Login.Content}</Content>
                <LoginForm id="login" captcha={recaptcha} />
                <SignUpContainer><FooterColor>{content.PublicPages.Login.SignUp} <NavLink to={"/register"}>Sign Up</NavLink></FooterColor></SignUpContainer>
                <CaptchaContainer>
                    <ReCAPTCHA ref={recaptcha} sitekey={sitekey} />
                </CaptchaContainer>
            </Wrapper>
            <MobileSuggestion onClick={() => { setShow(false) }} className={show ? "" : "hide"}>
                <MobileImage src={mobileMascot} alt="Desktop suggestion" />
            </MobileSuggestion>
        </LoginContainer>
    );
}

const MobileSuggestion = styled.div`
    opacity:0;
    pointer-events: none;
    z-index: -200;

    @media only screen and (max-width: ${size.maxWidthTablet}) {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        z-index:200;
        opacity: 1;
        pointer-events: all;
        background-color: rgba(0, 0, 0, .75);
        transition: .4s;
        animation: fade-opacity 1s;

        &.hide {
            opacity:0;
            pointer-events: none;
            z-index: -200;
            transition: .4s;
        }
    }
`;

const MobileImage = styled.img`
    position: absolute;
    bottom:5%;
    right:2%;
    width:95%;
    height: auto;
    transition: .4s;
    animation: image-scale 1.5s;
`;

const LoginContainer = styled.main`
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    justify-content:space-around;
    align-items: center;
    animation: popUp 1s;
    width:100%;
    min-height: 100vh;
    @media only screen and (max-width: ${size.maxWidthTablet}) {
        animation: popUp .5s;
    }
`;

const Wrapper = styled.section`
    margin-top:auto;
    margin-bottom: auto;
    text-align: center;

    > img {
        &:hover {
            cursor: pointer;
        }
    }

    @media only screen and (min-width: ${size.minWidthTablet}) {
        margin-top: 10rem;
    }

    @media only screen and (max-width: ${size.minWidthTablet}) {
        > img {
            max-width:200px;
        }
    }
`;

const Title = styled.h1`
    text-align: center;
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 41px;
    line-height: 155.52%;
    margin-bottom: 0;
    @media only screen and (max-width: ${size.minWidthTablet}) {
        line-height: 120%;
        font-size: 30px;
    }
`;

const Content = styled.p`
    font-size: 1rem;
    line-height: 26px;
    text-align: center;
    color: var(--font-grey);
    visibility: hidden; /* remove this to display text */
    margin-top: -3rem; /* remove this to display text */
`;

const SignUpContainer = styled.div`
    position:relative;
    animation: image-fade-up-main 1.5s;
    margin-top: 1rem;
`;

const FooterColor = styled.p`
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: var(--font-grey-dark);
    > a {
        color: var(--blue-text);
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        transition: .4s;
        &:hover {
            color: var(--main-bg);
            text-decoration: none;
            transition: .4s;
        }
    }
`;

const CaptchaContainer = styled.div`
  margin: 20px auto;
  text-align: center;
  & div{
    margin: auto;
  }
`;

export default LoginPage;