import { useState, lazy, Suspense, useMemo } from "react";
import styled from "styled-components";
import { getContent } from "../../hooks/useGetContent";
// import { size } from "../../utils/helpers";
import HomepageCloudSectionNew from "../../layout/sales/HomepageCloudSectionNew";
import RedLinkButton from "../../components/RedLinkButton";
// import checkIcon from "../../assets/modal-check-icon.png";
import { FAQBoxHomeNew } from "../../components/faqBoxBlueNew";
import Logo64 from "../../assets/logo-64.png";
import Imageshapes from "../../assets/commentsshape.png";
import Imageshapes2 from "../../assets/commentsshape2.png";
import Imageshapes3 from "../../assets/testicon1.png";
import Imageshapes4 from "../../assets/testicon2.png";
import tickimg from "../../assets/tick1.png";
import tickimg2 from "../../assets/close1.png";
import buildboximg from "../../assets/buillist.png";
import mobimg from "../../assets/mob.png";
import groupingPart from "../../assets/updateGrouping.png";
import groupingSml from "../../assets/GroupingSml.png";
// import infoIcon from "../../assets/mob.png";
import { urlPaths } from "../../utils/config";
import ComentingImage from "../../assets/vis.png";
import lead from "../../assets/lead.png";
import aud from "../../assets/aud.png";
import before1 from "../../assets/1 before.png";
import after1 from "../../assets/1after.png";
import before2 from "../../assets/2 before.png";
import after2 from "../../assets/2 after.png";
import before3 from "../../assets/3 before.png";
import after3 from "../../assets/3 after.png";
import afterCommnt from "../../assets/aftercommenting.png";
import afterrightCommnt from "../../assets/rightbef.png";
import boxend from "../../assets/Boxend.png";
// added
import Imageshapes_step from "../../assets/Imageshapes_step.png";
import Imageshapes_step2 from "../../assets/Imageshapes_step2.png";
import Imageshapes_step3 from "../../assets/Imageshapes_step3.png";
// import checkIconblue from "../../assets/checkboxblue.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Staging = lazy(() => import("../../layout/sales/HomepageStaging"));
const SecondSection = lazy(() => import("../../layout/sales/HomepageSecond"));
const ThirdSectionNew = lazy(() =>
  import("../../layout/sales/HomepageThirdNew")
);
// const ComparisionSection = lazy(() =>
//   import("../../layout/sales/HomepageComparisonSection")
// );
const FeatureSectionNew = lazy(() =>
  import("../../layout/sales/HomepageFeatureSectionNew")
);
const FeedbackSectionNew = lazy(() =>
  import("../../layout/sales/HomepageFeedbackSectionNew")
);
// const HomepageSixthSection = lazy(() =>
//   import("../../layout/sales/HomepageSisth")
// );
// const RoboticSection = lazy(() =>
//   import("../../layout/sales/HomepageRoboticSection")
// );
// const EffectSectionNew = lazy(() =>
//   import("../../layout/sales/HomepageEffectSectionNew")
// );
const HomepageFlowNew = lazy(() =>
  import("../../layout/sales/HomepageFlowNew")
);
// const HomepageMoneyBackSection = lazy(() =>
//   import("../../layout/sales/HomepageMoneyBack")
// );
const HomepageFAQSection = lazy(() =>
  import("../../layout/sales/HomepageFAQSection")
);
// const HomepageChallengeSection = lazy(() =>
//   import("../../layout/sales/HomepageChallange")
// );

const HomeNew = () => {
  const [content, setContent] = useState({});
  const [faq, setFAQ] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [clickedSpan, setClickedSpan] = useState("span5");

  const handleSpanClick = (spanId) => {
    // Update the clicked span identifier
    setClickedSpan(spanId);
  };

  const getTextForSpan = (spanId) => {
    // Define your text based on the spanId
    switch (spanId) {
      case "span1":
        return "Save the most effective comments in one place for more effective commenting, to become";
      case "span2":
        return "Commenting can be fun. Compete with other commenters and win.";
      case "span3":
        return "Build a list with people that you engage with for easier & faster commenting";
      case "span4":
        return "Be notified when your engagement list people post";
      case "span5":
        return "Choose the emotion that fits your brand voice for more authentic comments";
      case "span6":
        return "Choose the length of your comments to fit your style and post types";
      case "span7":
        return "Use a proven to standout style for a higher chance to get noticed";
      case "span8":
        return "Turn the comment from good to great by editing the generating comment";
      case "span9":
        return "Not satisfied with the comment? Get another one just by clicking on this button";
      case "span10":
        return "Ready to post the comment? Click here to copy your comment";
      case "span11":
        return "Become a better commenter every day with our academy tips";
      case "span12":
        return "Quickly learn how to use Commenter AI in the best possible way for maximum results";
      default:
        return "Default Text";
    }
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 768, // Adjust settings for screens smaller than 768 pixels
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useMemo(() => {
    const timer = setTimeout(() => {
      getContent().then((response) => {
        if (response["homepage"]) {
          setContent(response["homepage"]);
        }
        if (response["homeFAQ"]) {
          setFAQ(response["homeFAQ"]);
        }
        setIsLoading(false);
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  // if (Object.keys(content).length === 0 || Object.keys(faq).length === 0) {
  //     return <></>
  // }

  if (isLoading)
    return (
      <div
        style={{
          position: "absolute",
          minHeight: "100vh",
          width: "100%",
          background: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          top: 0,
          zIndex: 9999,
        }}
      >
        <div className="container">
          <div className="logo-container">
            <div className="logo">
              <LogoImg src={Logo64} alt="logo" />
            </div>
          </div>

          <div className="loading-letters">
            <span>L</span>
            <span>o</span>
            <span>a</span>
            <span>d</span>
            <span>i</span>
            <span>n</span>
            <span>g</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </div>
        </div>
      </div>
    );

  return (
    <Container>
      <Suspense
        fallback={
          <div
            style={{
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="container">
              <div className="logo-container">
                <div className="logo">
                  <LogoImg src={Logo64} alt="logo" />
                </div>
              </div>

              <div className="loading-letters">
                <span>L</span>
                <span>o</span>
                <span>a</span>
                <span>d</span>
                <span>i</span>
                <span>n</span>
                <span>g</span>
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </div>
            </div>
          </div>
        }
      >
        <Staging
          subtitle={content.subtitle ?? ""}
          title={content.title ?? ""}
          options={content.stagingOptions}
          stagingButton={content.stagingButton ?? ""}
          links={content.stagingLinks ?? []}
          image={content?.stagingImage}
          testimonials={content.fifthSectionContent?.content}
        >
          {content.description ?? ""}
        </Staging>
      </Suspense>

      <Suspense>
        <SecondSection
          title={content.secondSectionTitle}
          subtitle={content.secondSectionSubtitle}
          images={content.secondSectionIcons}
          content={content.secondSectionContent?.content}
        />
      </Suspense>
      <Commenting>
        <h2>Commenting can get you</h2>
        <CommentingMain>
          <ComentingLeft>
            <ComentingBox>
              <ComentingImagewrape src={ComentingImage} alt="visibility5" />
              <h3>Visibility</h3>
              <p>Commenting allows you to attract more
                people to your profile, increase your
                impressions & web visits.</p>
            </ComentingBox>
            <ComentingBox>
              <ComentingImagewrape src={aud} alt="visibility5" />
              <h3>Audience</h3>
              <p>Commenting allows you to increase your followers, post engagements and community.</p>
            </ComentingBox>
            <ComentingBox>
              <ComentingImagewrape src={lead} alt="visibility5" />
              <h3>Leads</h3>
              <p>Commenting allows you to start conversations
                with your potential leads, get sign ups, demos and make sales.</p>
            </ComentingBox>
          </ComentingLeft>
          <ComentingRight>
            <Commntrghtwrapper>
              <Commentingafter>
                <CommentingDiv>
                  <h4>Before: 12 Engagements</h4>
                  <ComentDicmainimg>
                    <ComentingImagebefore src={before1} alt="visibility5" />
                  </ComentDicmainimg>
                </CommentingDiv>
                <CommentingDiv>
                  <Blutexth4>
                    <h4>After: 413 Engagements</h4>
                    <h4>Total: +28,891.5%</h4>
                  </Blutexth4>
                  <ComentDicmainimg>
                    <ComentingImagebefore src={after1} alt="visibility5" />
                  </ComentDicmainimg>

                </CommentingDiv>
                <ComentingImagebefore1 src={afterCommnt} alt="visibility5" />
              </Commentingafter>
              <Commentingafter>
                <CommentingDiv>
                  <h4>Before: 79 Profile Views</h4>
                  <ComentDicmainimg>
                    <ComentingImagebefore src={before2} alt="visibility5" />
                  </ComentDicmainimg>

                </CommentingDiv>
                <CommentingDiv>
                  <Blutexth4>
                    <h4>After: 476 Profile Views</h4>
                    <h4>Total: +502.53%</h4>
                  </Blutexth4>
                  <ComentDicmainimg>
                    <ComentingImagebefore src={after2} alt="visibility5" />
                  </ComentDicmainimg>

                </CommentingDiv>
                <ComentingImagebefore2 src={afterrightCommnt} alt="visibility5" />
              </Commentingafter>
              <Commentingafter>
                <CommentingDiv>
                  <h4>Before: 234 Impressions</h4>
                  <ComentDicmainimg>
                    <ComentingImagebefore src={before3} alt="visibility5" />
                  </ComentDicmainimg>

                </CommentingDiv>
                <CommentingDiv>
                  <Blutexth4>
                    <h4>After: 19,538 Impressions</h4>
                    <h4>Total: +20,573.8%</h4>
                  </Blutexth4>
                  <ComentDicmainimg>
                    <ComentingImagebefore src={after3} alt="visibility5" />
                  </ComentDicmainimg>

                </CommentingDiv>
                <ComentingImagebefore2 src={afterrightCommnt} alt="visibility5" />
              </Commentingafter>
            </Commntrghtwrapper>
            <CommentEndafter>
              <ImgeComntendbef>
                <ComentingImagebefore3 src={boxend} alt="visibility5" />
              </ImgeComntendbef>
            </CommentEndafter>
          </ComentingRight>
        </CommentingMain>
      </Commenting>




      <Suspense>
        <Sectioninnerouter>
          <Sectioninner>
            <Title>
              <h2>
                Writing comments on LinkedIn<sup>TM</sup> can be challenging
              </h2>
            </Title>
            <Commentrow>
              <Commentwraper>
                <Boxcomment>
                  <Imageshape
                    src={Imageshapes_step} // Path to your image from the public folder
                    alt="img"
                    width={60} // Set the width of the image
                    height={60} // Set the height of the image
                  />
                  <p className="partext">
                    Commenting daily for 3+ hours is unsustainable & risky
                  </p>
                </Boxcomment>
              </Commentwraper>
              <Commentwraper>
                <Boxcomment>
                  <Imageshape
                    src={Imageshapes_step2} // Path to your image from the public folder
                    alt="img"
                    width={60} // Set the width of the image
                    height={60} // Set the height of the image
                  />
                  <p className="partext">
                    Coming up with 20+ ideas for meaningful comments is hard
                  </p>
                </Boxcomment>
              </Commentwraper>
              <Commentwraper>
                <Boxcomment>
                  <Imageshape
                    src={Imageshapes_step3} // Path to your image from the public folder
                    alt="img"
                    width={60} // Set the width of the image
                    height={60} // Set the height of the image
                  />
                  <p className="partext">
                    Writing valuable comments in less than 3 minutes is tiring
                  </p>
                </Boxcomment>
              </Commentwraper>
            </Commentrow>
            <Writingcomments>
              <Imgcomments>
                <Imageshape2
                  src={Imageshapes2} // Path to your image from the public folder
                  alt="img"
                  width={124} // Set the width of the image
                  height={124} // Set the height of the image
                />
                <div>
                  <Imageshape3
                    src={Imageshapes3} // Path to your image from the public folder
                    alt="img"
                    width={40} // Set the width of the image
                    height={40} // Set the height of the image
                  />
                  <p>
                    We’ve been there - in all these situations. That’s why we
                    built Commenter AI. <br />{" "}
                    <span>Bogomil Stoev, Co-Founder & CEO - Commenter.ai</span>
                  </p>

                  <Imageshape4
                    src={Imageshapes4} // Path to your image from the public folder
                    alt="img"
                    width={40} // Set the width of the image
                    height={40} // Set the height of the image
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="66"
                    height="50"
                    viewBox="0 0 66 50"
                    fill="none"
                  >
                    <path d="M0 50L10.9349 0H66L0 50Z" fill="#0050B2"></path>
                  </svg>
                </div>
              </Imgcomments>
            </Writingcomments>
          </Sectioninner>
        </Sectioninnerouter>
      </Suspense>
      <Suspense>
        <HomepageFlowNew
          title={content.flowTitle}
          content={content.flowContent?.content}
        />
      </Suspense>

      <Suspense>
        <ThirdSectionNew
          title={content.thirdSectionTitle ?? ""}
          subtitle={content.thirdSectionSubtitle ?? ""}
          description={content.thirdSectionDescription ?? ""}
          content={content?.thirdSectionColumns?.content}
          influencer={content?.influencerMessage ?? ""}
          influencerMobile={content?.influencerMessageMobile ?? ""}
        />
      </Suspense>
      {/* <Suspense>
                <ComparisionSection
                    title={content.comparisonSectionTitle}
                    comparison={content.comparison}
                />
            </Suspense> */}
      <Suspense>
        <FeatureSectionNew
          title={content.featureSectionTitle}
          subtitle={content.featureSectionSubTitle}
          images={content.featureSectionImages}
        />
      </Suspense>

      <Suspense>
        <DoNOTuse>
          <Usebox1>
            <h2>Who is Commenter.ai for:</h2>

            <div>
              <Imageshape5
                src={tickimg} // Path to your image from the public folder
                alt="img"
                width={22} // Set the width of the image
                height={22} // Set the height of the image
              />
              <p>
                Creators and solopreneurs who want to grow their audience
                without spending 20+ hours on LinkedIn<sup>TM</sup> every week (that’s a
                part-time job!)
              </p>
            </div>
            <div>
              <Imageshape5
                src={tickimg} // Path to your image from the public folder
                alt="img"
                width={22} // Set the width of the image
                height={22} // Set the height of the image
              />
              <p>
                New LinkedIn<sup>TM</sup> users who need to speed up their commenting game &
                grow faster
              </p>
            </div>
            <div>
              <Imageshape5
                src={tickimg} // Path to your image from the public folder
                alt="img"
                width={22} // Set the width of the image
                height={22} // Set the height of the image
              />
              <p>
                Social media management freelancers and teams who want to scale
                up faster
              </p>
            </div>
            <div>
              <Imageshape5
                src={tickimg} // Path to your image from the public folder
                alt="img"
                width={22} // Set the width of the image
                height={22} // Set the height of the image
              />
              <Lastptag>
                Sales professionals who want to generate and nurture leads
              </Lastptag>
            </div>
          </Usebox1>
          <Usebox2>
            <h2>Do NOT use Commenter.ai if:</h2>

            <div>
              <Imageshape5
                src={tickimg2} // Path to your image from the public folder
                alt="img"
                width={22} // Set the width of the image
                height={22} // Set the height of the image
              />
              <p>You want a fully automated solution</p>
            </div>
            <div>
              <Imageshape5
                src={tickimg2} // Path to your image from the public folder
                alt="img"
                width={22} // Set the width of the image
                height={22} // Set the height of the image
              />
              <p>You're hesitant to use AI for engagement</p>
            </div>
            <div>
              <Imageshape5
                src={tickimg2} // Path to your image from the public folder
                alt="img"
                width={22} // Set the width of the image
                height={22} // Set the height of the image
              />
              <p>You prefer quantity over quality in engagement</p>
            </div>
            <div>
              <Imageshape5
                src={tickimg2} // Path to your image from the public folder
                alt="img"
                width={22} // Set the width of the image
                height={22} // Set the height of the image
              />
              <Pseclast>
                Your commenting is already strong and you don’t need to speed it
                up
              </Pseclast>
            </div>
            <div>
              <Imageshape5
                src={tickimg2} // Path to your image from the public folder
                alt="img"
                width={22} // Set the width of the image
                height={22} // Set the height of the image
              />
              <Lastptag2>
                You don’t believe in the importance of commenting
              </Lastptag2>
            </div>
          </Usebox2>
        </DoNOTuse>
      </Suspense>

      <Suspense>
        <Buildlistinner>
          <MobImg src={mobimg} />
          <Buildbox1>
            <p>Commenting can be fun. Compete with other commenters and win.</p>
            <p>
              Save the most effective comments in one place for more effective commenting, to become
            </p>
            <p>
              Choose the length of your comments to fit your style and post
              types
            </p>
            <p>
              Generate comments that represent your voice and ideas for more
              authentic comments
            </p>
            <p>
              Not satisfied with the comment? Get another one just by clicking
              on this button
            </p>
          </Buildbox1>
          <Buildbox2>
            <p>
              Build a list with people that you engage with for easier & faster
              commenting
            </p>

            {/* <MobImg
                    src={mobimg} // Path to your image from the public folder
                    alt="img"
                    width={606} // Set the width of the image
                    height={685} // Set the height of the image
                /> */}
            <Imagebuildbox
              src={buildboximg} // Path to your image from the public folder
              alt="img"
              width={606} // Set the width of the image
              height={685} // Set the height of the image
            />
            <Academytips>
              <div>
                Become a better commenter every day with our academy tips
              </div>
              <div>
                Quickly learn how to use Commenter AI in the best possible way
                for maximum results
              </div>
            </Academytips>
          </Buildbox2>
          <Buildbox3>
            <p>
              Be notified when your engagement list posts and comment before all
            </p>
            <p>
              Choose the emotion that fits your brand voice for more authentic
              comments
            </p>
            <p>
              Use a proven to standout style for a higher chance to get noticed
            </p>
            <p>
              Turn the comment from good to great by editing the generated
              comment
            </p>
            <p>Ready to post the comment? Click here to copy your comment</p>
          </Buildbox3>
        </Buildlistinner>
      </Suspense>

      {/* mobile grouping part section>>>>>>> */}
      <Suspense>
        <GroupingParent>
          <p>{clickedSpan && getTextForSpan(clickedSpan)}</p>
          <GroupingPostion>
            <ImagesGrouping src={groupingPart} alt="img" />
            <span
              onClick={() => handleSpanClick("span1")}
              className={clickedSpan === "span1" ? "clicked" : ""}
            >
              <GroupingSmlImg src={groupingSml} alt="img" />
            </span>
            <span
              onClick={() => handleSpanClick("span2")}
              className={clickedSpan === "span2" ? "clicked" : ""}
            >
              <GroupingSmlImg src={groupingSml} alt="img" />
            </span>
            <span
              onClick={() => handleSpanClick("span3")}
              className={clickedSpan === "span3" ? "clicked" : ""}
            >
              <GroupingSmlImg src={groupingSml} alt="img" />
            </span>
            <span
              onClick={() => handleSpanClick("span4")}
              className={clickedSpan === "span4" ? "clicked" : ""}
            >
              <GroupingSmlImg src={groupingSml} alt="img" />
            </span>
            <span
              onClick={() => handleSpanClick("span5")}
              className={clickedSpan === "span5" ? "clicked" : ""}
            >
              <GroupingSmlImg src={groupingSml} alt="img" />
            </span>
            <span
              onClick={() => handleSpanClick("span6")}
              className={clickedSpan === "span6" ? "clicked" : ""}
            >
              <GroupingSmlImg src={groupingSml} alt="img" />
            </span>
            <span
              onClick={() => handleSpanClick("span7")}
              className={clickedSpan === "span7" ? "clicked" : ""}
            >
              <GroupingSmlImg src={groupingSml} alt="img" />
            </span>
            <span
              onClick={() => handleSpanClick("span8")}
              className={clickedSpan === "span8" ? "clicked" : ""}
            >
              <GroupingSmlImg src={groupingSml} alt="img" />
            </span>
            <span
              onClick={() => handleSpanClick("span9")}
              className={clickedSpan === "span9" ? "clicked" : ""}
            >
              <GroupingSmlImg src={groupingSml} alt="img" />
            </span>
            <span
              onClick={() => handleSpanClick("span10")}
              className={clickedSpan === "span10" ? "clicked" : ""}
            >
              <GroupingSmlImg src={groupingSml} alt="img" />
            </span>
            <span
              onClick={() => handleSpanClick("span11")}
              className={clickedSpan === "span11" ? "clicked" : ""}
            >
              <GroupingSmlImg src={groupingSml} alt="img" />
            </span>
            <span
              onClick={() => handleSpanClick("span12")}
              className={clickedSpan === "span12" ? "clicked" : ""}
            >
              <GroupingSmlImg src={groupingSml} alt="img" />
            </span>
          </GroupingPostion>
        </GroupingParent>
      </Suspense>

      <HomepageCloudSectionNew
        title={content.fourthSection}
        button={content.fourthSectionButton}
        links={content.fourthSectionList}
        staticImages={content.staticImages}
        influencer={content.influencer}
      >
        <Cloudiv>
          <SvgImge>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="85"
              height="83"
              viewBox="0 0 85 83"
              fill="none"
            >
              <path
                d="M44.5683 7.43262H40.4902V23.087H44.5683V7.43262Z"
                fill="#41BCFF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40.49 6.24854H45.7519V24.3366H39.2402V6.24854H40.49ZM43.384 8.61641H41.7397V21.9029H43.384V8.61641Z"
                fill="#1B2E4A"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.5285 13.6156C45.9488 13.6156 48.7113 10.8531 48.7113 7.43282C48.7113 4.01253 45.9488 1.25 42.5285 1.25C39.1082 1.25 36.3457 4.01253 36.3457 7.43282C36.3457 10.8531 39.1082 13.6156 42.5285 13.6156Z"
                fill="#41BCFF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.5292 0C44.5682 0 46.4099 0.855063 47.7912 2.17056C49.1067 3.48605 49.9618 5.39352 49.9618 7.43254C49.9618 9.47155 49.1067 11.3132 47.7912 12.6945C46.6073 13.8785 45.0944 14.602 43.3843 14.7993V12.3656C44.4367 12.1683 45.3575 11.7079 46.081 10.9844C47.0019 10.0635 47.5281 8.81381 47.5281 7.43254C47.5281 6.05127 47.0019 4.80155 46.081 3.88071C45.1602 2.95986 43.9105 2.43365 42.5292 2.43365C41.1479 2.43365 39.8982 3.02563 38.9774 3.88071C38.0565 4.80155 37.5303 6.05127 37.5303 7.43254C37.5303 8.81381 38.1223 10.0635 38.9774 10.9844C39.7009 11.7079 40.6218 12.2341 41.6742 12.3656V14.7993C39.964 14.602 38.3854 13.8127 37.2672 12.6945C35.9517 11.379 35.0967 9.47155 35.0967 7.43254C35.0967 5.39352 35.9517 3.55183 37.2672 2.17056C38.6485 0.855063 40.4902 0 42.5292 0Z"
                fill="#1B2E4A"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.5294 19.5349C62.1961 19.5349 78.7713 33.0845 83.3097 51.3699C84.3621 55.5795 83.5071 59.7233 80.8103 63.1436C78.1135 66.5639 74.3644 68.4056 69.9575 68.4056H14.9698C10.6287 68.4056 6.81373 66.5639 4.11697 63.1436C1.42021 59.7233 0.565133 55.5795 1.61753 51.3699C6.28753 33.0845 22.8628 19.5349 42.5294 19.5349Z"
                fill="#41BCFF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.5289 19.5352C60.3538 19.5352 75.6136 30.6511 81.7306 46.3713C70.0227 48.1472 56.6705 49.1338 42.5289 49.1338C28.3873 49.1338 15.0351 48.1472 3.32715 46.3713C9.4442 30.6511 24.7039 19.5352 42.5289 19.5352Z"
                fill="#0050B2"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.5295 18.3511C52.5273 18.3511 61.8015 21.7714 69.1025 27.4938C76.6008 33.3477 82.1259 41.5695 84.4938 51.1069C85.0858 53.409 85.1516 55.6453 84.6911 57.8159C84.2307 59.9865 83.2441 62.0255 81.7971 63.933C80.35 65.7746 78.5741 67.2217 76.6008 68.2083C74.6276 69.1949 72.3913 69.6554 70.0234 69.6554H65.2218L65.5507 69.3265C66.0111 68.8661 66.0769 68.0768 65.6165 67.6163C65.3534 67.3532 65.0245 67.2217 64.6956 67.2217H69.9576C71.9308 67.2217 73.8383 66.827 75.4827 65.972C77.127 65.1827 78.6399 63.933 79.8238 62.3544C81.0735 60.7758 81.8628 59.0656 82.2575 57.2239C82.6521 55.448 82.5863 53.5406 82.1259 51.5673C79.8896 42.622 74.6934 34.7948 67.5897 29.2697C60.6834 23.8762 51.9353 20.6532 42.4637 20.6532C32.9922 20.6532 24.3099 23.8762 17.3378 29.2697C10.2341 34.7948 5.03793 42.5562 2.80159 51.5673C2.34117 53.5406 2.27539 55.3823 2.67004 57.2239C3.06468 58.9999 3.85399 60.7758 5.10371 62.3544C6.35343 63.933 7.80047 65.1169 9.44484 65.972C11.0892 66.7613 12.9309 67.2217 14.9699 67.2217H51.6064C50.9487 67.2217 50.4225 67.7479 50.4225 68.4057L50.3567 69.5896H14.9699C12.602 69.5896 10.3657 69.0634 8.39245 68.1425C6.4192 67.1559 4.64328 65.7089 3.19624 63.8672C1.74919 62.0255 0.762575 59.9207 0.302152 57.7501C-0.158271 55.5796 -0.0924945 53.3432 0.499478 51.0411C2.86737 41.5695 8.39244 33.2819 15.8908 27.428C23.2575 21.7056 32.5318 18.3511 42.5295 18.3511Z"
                fill="#1B2E4A"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.8883 38.6096H59.1703C63.8403 38.6096 67.6552 42.4245 67.6552 47.0946C67.6552 51.7646 63.8403 55.5795 59.1703 55.5795H25.8883C21.2183 55.5795 17.4033 51.7646 17.4033 47.0946C17.4033 42.4245 21.2183 38.6096 25.8883 38.6096Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.8882 36.7681H59.1702C61.9985 36.7681 64.5637 37.952 66.4054 39.7937C68.2471 41.6354 69.431 44.2006 69.431 47.0289C69.431 49.8572 68.2471 52.4224 66.4054 54.3299C64.5637 56.1716 61.9327 57.3555 59.1702 57.3555H25.8882C23.0599 57.3555 20.4946 56.1716 18.5872 54.3299C16.7455 52.4882 15.5615 49.923 15.5615 47.0289C15.5615 44.2006 16.7455 41.6354 18.5872 39.7937C20.4946 37.952 23.0599 36.7681 25.8882 36.7681ZM59.1702 40.3857H25.8882C24.0465 40.3857 22.4021 41.1092 21.2182 42.3589C20.0342 43.5429 19.2449 45.253 19.2449 47.0289C19.2449 48.8706 19.9684 50.515 21.2182 51.6989C22.4021 52.8829 24.1122 53.6722 25.8882 53.6722H59.1702C61.0119 53.6722 62.6562 52.9486 63.8402 51.6989C65.0241 50.515 65.8134 48.8048 65.8134 47.0289C65.8134 45.1872 65.0899 43.5429 63.8402 42.3589C62.6562 41.175 61.0119 40.3857 59.1702 40.3857Z"
                fill="#1B2E4A"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M58.118 45.4502C58.6442 52.225 52.1982 54.5271 48.6464 50.9753C45.0288 47.2919 47.4625 40.4513 54.303 41.5695C53.7111 42.6219 52.5271 43.8059 53.7768 45.3844C55.0923 47.0288 56.6709 45.9106 58.118 45.4502Z"
                fill="#1B2E4A"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M37.9252 45.2532C39.0434 52.0938 32.1371 54.5932 28.5852 50.9098C24.9018 47.1607 27.467 40.5832 34.2418 41.5698C33.7156 42.688 32.5317 44.2666 33.8472 45.6479C35.2942 47.0949 36.8728 46.0425 37.9252 45.2532Z"
                fill="#1B2E4A"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M52.8551 69.6555L52.6578 72.3523L52.2632 78.2063L61.6032 69.5898H61.4716L64.0368 67.1561H64.6946C65.0235 67.1561 65.3523 67.2876 65.6154 67.5507C66.0759 68.0112 66.0101 68.8005 65.5497 69.2609L51.737 81.9554C51.4739 82.1527 51.2108 82.2843 50.8161 82.2843C50.1584 82.2185 49.6322 81.6923 49.6979 80.9688L50.4873 68.2743C50.4873 67.6165 51.0134 67.0903 51.6712 67.0903H52.9867L52.8551 69.6555Z"
                fill="#1B2E4A"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M52.3301 78.2061L52.7247 72.3522L53.2509 62.8806L67.6556 64.1304L57.4605 73.4704L52.3301 78.2061Z"
                fill="#41BCFF"
              />
            </svg>
          </SvgImge>
          <PlanboxTwo>
            <ProPlan>"My impressions are up by 845%" J. Gill</ProPlan>

            <FAQSectionWrapper className="faqHomePageWrapper">
              <FaqinnerSection  >
                <FAQBoxHomeNew
                  question="Get an insightful comment with a click of a button and save your time"
                  answer="test"
                />
                <FAQBoxHomeNew
                  className="heightcustom"
                  question="Use our unique comment-specializing AI technology for relevant and engaging comments"
                  answer="test"
                />
                {/* <FAQBoxHomeNew
                  className="heightcustom"
                  question="Generate comments that represent your voice and ideas for more authentic comments"
                  answer="test"
                />
                <FAQBoxHomeNew
                  question="Choose the length of your comments to fit your style and post types"
                  answer="test"
                /> */}
              </FaqinnerSection>

              <FaqinnerSection2>
                <FAQBoxHomeNew
                  question="Get comments in various languages, expanding your global reach and engagement"
                  answer="test"
                />
                <FAQBoxHomeNew
                  question="Join a secret community & get personalized support to fast-track your business goals"
                  answer="test"
                />
                {/* <FAQBoxHomeNew
                  question="Build a list with people that you engage with for easier & faster commenting"
                  answer="test"
                />
                <FAQBoxHomeNew
                  className="max-heightcustom"
                  question="Join a secret community & get personalized support to fast-track your business goals"
                  answer="test"
                /> */}
              </FaqinnerSection2>
            </FAQSectionWrapper>
            <Btnalign>
              <RedLinkButton
                href={urlPaths.register}
                text="Get Your First 140 Comments FREE"
                rounded={false}
              />
              <CancleAnytime>
                <TextCancel>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    viewBox="0 0 29 29"
                    fill="none"
                  >
                    <circle
                      cx="14.5"
                      cy="14.5"
                      r="13"
                      fill="#0050B2"
                      stroke="#ECECEC"
                      strokeWidth="3"
                    />
                    <path
                      d="M8.75 15.5542L12.5165 19.2917L21.2083 10.6667"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                  <Text>No credit card required</Text>
                </TextCancel>
                <TextCancel>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    viewBox="0 0 29 29"
                    fill="none"
                  >
                    <circle
                      cx="14.5"
                      cy="14.5"
                      r="13"
                      fill="#0050B2"
                      stroke="#ECECEC"
                      strokeWidth="3"
                    />
                    <path
                      d="M8.75 15.5542L12.5165 19.2917L21.2083 10.6667"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                  <Text>Approved by top experts</Text>
                </TextCancel>
              </CancleAnytime>
            </Btnalign>
          </PlanboxTwo>
        </Cloudiv>
      </HomepageCloudSectionNew>

      {/* <Suspense>
        <EffectSectionNew
          title={content.effectSectionTitle}
          content={content.effectSectionContent?.content}
          bottomContent={content.effectSectionBottomContent}
          button={content.ninthSectionButton}
          links={content.ninthSectionList}
        />
      </Suspense> */}

      {/* <Suspense>
                <HomepageMoneyBackSection
                    title={content.tenthSectionTitle}
                    content={content.tenthSectionContent}
                    staticImages={content?.staticImages}
                />
            </Suspense> */}
      <Suspense>
        <FeedbackSectionNew
          title={content.fifthSectionTitle}
          content={content.fifthSectionContent?.content}
          influencer={content.influencer}
        />
      </Suspense>
      <Suspense>
        <HomepageFAQSection
          content={content.faqSection}
          faq={faq}
          staticImages={content?.staticImages}
        />
      </Suspense>
    </Container>
  );
};
const GroupingParent = styled.div`
  padding: 28px 15px 26px;
  margin-bottom: 72px;
  margin-top: 50px;
  background: #e3e3e3;
  @media only screen and (min-width: 767px) {
    display: none;
  }
  p {
    padding: 0 6px;
    max-width: 396px;
    height: 59px;
    margin: auto;
    margin-bottom: 12px;
    text-align: center;
    border-radius: 40px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 124.5%;
    color: white;
    background: #0050b2;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 480px) {
      font-size: 13px;
    }
  }
`;

const GroupingPostion = styled.div`
  display: flex;
  justify-content: center;
  box-shadow: 0px 4px 55.2px 0px rgba(0, 0, 0, 0.32);
  max-width: 406px;
  height: auto;
  position: relative;
  margin: auto;
  padding: 0;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
  span:nth-of-type(1) {
    position: absolute;
    width: 17px;
    height: 17px;
    top: 6px;
    left: 38px;
    cursor: pointer;
    @media only screen and (max-width: 480px) {
      top: 4px;
      left: 24px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  span:nth-of-type(2) {
    position: absolute;
    width: 17px;
    height: 17px;
    top: 6px;
    left: 80px;
    cursor: pointer;
    @media only screen and (max-width: 480px) {
      top: 4px;
      left: 55px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  span:nth-of-type(3) {
    position: absolute;
    width: 17px;
    height: 17px;
    top: 6px;
    left: 120px;
    cursor: pointer;
    @media only screen and (max-width: 480px) {
      top: 4px;
      left: 80px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  span:nth-of-type(4) {
    position: absolute;
    width: 17px;
    height: 17px;
    top: 8px;
    right: 40px;
    cursor: pointer;
    @media only screen and (max-width: 480px) {
      top: 4px;
      right: 35px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  span:nth-of-type(5) {
    position: absolute;
    width: 17px;
    height: 17px;
    top: 72px;
    left: 14px;
    cursor: pointer;
    @media only screen and (max-width: 480px) {
      top: 52px;
      left: 14px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  span:nth-of-type(6) {
    position: absolute;
    width: 17px;
    height: 17px;
    top: 134px;
    left: 0px;
    cursor: pointer;
    @media only screen and (max-width: 480px) {
      top: 95px;
      left: -2px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  span:nth-of-type(7) {
    position: absolute;
    width: 17px;
    height: 17px;
    top: 134px;
    right: 15px;
    cursor: pointer;
    @media only screen and (max-width: 480px) {
      top: 84px;
      right: 15px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  span:nth-of-type(8) {
    position: absolute;
    width: 17px;
    height: 17px;
    top: 228px;
    left: 8px;
    cursor: pointer;
    @media only screen and (max-width: 480px) {
      top: 165px;
      left: 8px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  span:nth-of-type(9) {
    position: absolute;
    width: 17px;
    height: 17px;
    bottom: 176px;
    left: 162px;
    cursor: pointer;
    @media only screen and (max-width: 480px) {
      bottom: 124px;
      left: 112px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  span:nth-of-type(10) {
    position: absolute;
    width: 17px;
    height: 17px;
    bottom: 176px;
    right: 58px;
    cursor: pointer;
    @media only screen and (max-width: 480px) {
      bottom: 125px;
      right: 47px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  span:nth-of-type(11) {
    position: absolute;
    width: 17px;
    height: 17px;
    bottom: 112px;
    left: 2px;
    cursor: pointer;
    @media only screen and (max-width: 480px) {
      bottom: 79px;
      left: 2px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  span:nth-of-type(12) {
    position: absolute;
    width: 17px;
    height: 17px;
    bottom: 56px;
    right: 20px;
    cursor: pointer;
    @media only screen and (max-width: 480px) {
      bottom: 36px;
      right: 20px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
`;
const GroupingSmlImg = styled.img.attrs({
  loading: "lazy",
})``;
const ImagesGrouping = styled.img.attrs({
  loading: "lazy",
})`
  width: 100%;
  margin: auto;
`;
// const GroupingPart = styled.div`
//   text-align: center;
// `;
const Buildlistinner = styled.div`
  display: flex;
  justify-content: center;
  padding: 80px 130px;
  position: relative;

  @media only screen and (max-width: 1023px) {
    flex-direction: column;
    padding: 40px 15px 80px;
  }
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;
const Buildbox1 = styled.div`
  padding-top: 114px;
  position: relative;
  right: -84px;
  z-index: -1;

  @media only screen and (max-width: 1360px) {
    padding-top: 125px;
    right: -22px;
  }
  @media only screen and (max-width: 1199px) {
    padding-top: 81px;
    right: -114px;
  }
  @media only screen and (max-width: 1023px) {
    position: unset;
    padding-top: 0px;
  }

  > p {
    width: 344px;
    // height: 88px;
    border-radius: 10px;
    background: var(--w, #fff);
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.08);
    padding: 25px 15px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    color: var(--wh, #333);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 124.5%; /* 19.92px */

    @media only screen and (max-width: 1360px) {
      width: 250px;
      font-size: 14px;
    }
    @media only screen and (max-width: 1199px) {
      font-size: 12px;
      margin-bottom: 20px;
      padding: 20px 30px 20px 15px;
    }
    @media only screen and (max-width: 1023px) {
      width: 100%;
      font-size: 15px;
      box-sizing: border-box;
      padding: 20px 15px;
    }
  }
`;
const Buildbox2 = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;

  @media only screen and (max-width: 1023px) {
    align-items: start;
  }

  > p {
    width: 344px;
    // height: 88px;
    border-radius: 10px;
    background: var(--w, #fff);
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.08);
    padding: 15px;
    display: flex;
    align-items: center;
    margin-bottom: -19px;
    color: var(--wh, #333);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 124.5%; /* 19.92px */

    @media only screen and (max-width: 1360px) {
      width: 250px;
      font-size: 14px;
    }
    @media only screen and (max-width: 1199px) {
      width: 254px;
      font-size: 12px;
      padding: 15px;
    }
    @media only screen and (max-width: 1023px) {
      margin-bottom: 0px;
      width: 100%;
      font-size: 15px;
      box-sizing: border-box;
    }
  }
  > img {
    @media only screen and (max-width: 1360px) {
      width: 100%;
      // max-width: 500px;
      height: auto;
    }
    @media only screen and (max-width: 1199px) {
      max-width: 470px;
    }
    @media only screen and (max-width: 1023px) {
      display: none;
    }
  }
`;

const Academytips = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;

  @media only screen and (max-width: 1023px) {
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }

  > div {
    width: 303px;
    // height: 88px;
    border-radius: 10px;
    background: var(--w, #fff);
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.08);
    padding: 15px 25px;
    display: flex;
    align-items: center;
    margin-top: -19px;
    z-index: -1;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 124.5%; /* 19.92px */

    @media only screen and (max-width: 1360px) {
      width: 254px;
      font-size: 15px;
      margin-top: -6px;
    }
    @media only screen and (max-width: 1199px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 1023px) {
      margin-top: 0px;
      width: 100%;
      font-size: 15px;
      box-sizing: border-box;
      padding: 20px 15px;
    }
  }
`;
const Buildbox3 = styled.div` 
    padding-top: 114px;
    position: relative;
    left: -87px;
    z-index: -1;
    
    @media only screen and (max-width: 1360px) {
      padding-top: 125px;
      left: -23px;
    }
    @media only screen and (max-width: 1199px) {
      padding-top: 81px;
      left: -117px;
    }
    @media only screen and (max-width: 1023px) {
      position: unset;
      padding-top: 0px;
    }
>p {
    width: 344px;
    // height: 88px;
    border-radius: 10px;
    background: var(--w, #FFF);
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.08);
    padding: 25px 0px 25px 45px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    color: var(--wh, #333);
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 124.5%; /* 19.92px */

@media only screen and (max-width: 1360px) {
  width: 250px;
  font-size: 14px;
}
    @media only screen and (max-width: 1199px) {
      font-size: 12px;
      margin-bottom: 20px;
      padding: 20px 15px 20px 39px;
    }
    @media only screen and (max-width: 1023px) {
      width: 100%;
      font-size: 15px;
      box-sizing: border-box;
      padding: 20px 15px;
    }
}
}
`;
const Imagebuildbox = styled.img.attrs({
  loading: "lazy",
})``;

const Usebox2 = styled.div` 

width: 100%;
height:100%
max-width:598px;
min-height: 423px;
border-radius: 31px;
background: var(--blue, #0050B2);
padding: 40px 50px 15px;
box-sizing: border-box;
max-width: 598px;

@media only screen and (max-width: 1199px) {
    padding: 30px;
  }
  @media only screen and (max-width: 991px) {
    width: 100%;
    padding: 20px 20px;
    border-radius: 15px;
    min-height:auto;
  }

>h2 {
    color: #fff;
font-family: Space Grotesk;
font-size: 41px;
font-style: normal;
font-weight: 700;
line-height: 134.1%; /* 54.981px */
letter-spacing: -1.64px;
margin-top: 0px;
margin-bottom: 24px;
white-space: nowrap;

@media only screen and (max-width: 1200px) {
  font-size: 35px;
}
@media only screen and (max-width:991px){
  white-space: break-spaces;
}

@media only screen and (max-width: 767px) {
    font-size: 26px;
  }
}

>div {
        display: flex;
        /* justify-content: center; */
        gap: 12px;
            padding-left: 17px;

            @media only screen and (max-width: 767px) {
              padding-left: 0px;
            }
    >p {
        color: var(--wh, #fff);
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 124.5%; /* 22.41px */
      margin-bottom: 28px;
      margin-top: 0px;
      max-width: 400px;
    }

}
`;
const Usebox1 = styled.div`
  width: 100%;
  max-width: 598px;
  min-height: 423px;
  border-radius: 31px;
  background: var(--w, #fff);
  box-shadow: rgba(0, 0, 0, 0.08) 0px -18px 53px 0px inset,
    rgba(0, 0, 0, 0.11) -6px -10px 28px 0px inset;
  filter: drop-shadow(rgba(0, 80, 178, 0.18) 0px 4px 52px);
  padding: 40px 50px 15px;
  box-sizing: border-box;
  height: 100%;

  @media only screen and (max-width: 1199px) {
    padding: 30px;
  }
  @media only screen and (max-width: 991px) {
    width: 100%;
    padding: 20px 20px;
    border-radius: 15px;
    min-height: auto;
  }

  > h2 {
    color: rgb(34, 34, 34);
    font-family: "Space Grotesk";
    font-size: 41px;
    font-style: normal;
    font-weight: 700;
    line-height: 134.1%;
    margin-top: 0px;
    margin-bottom: 26px;
    white-space: nowrap;
    letter-spacing: -1.64px;
    @media only screen and (max-width: 1200px) {
      font-size: 35px;
    }

    @media only screen and (max-width: 991px) {
      white-space: break-spaces;
    }

    @media only screen and (max-width: 767px) {
      font-size: 26px;
    }
  }

  > div {
    display: flex;
    /* justify-content: center; */
    gap: 12px;
    padding-left: 17px;

    @media only screen and (max-width: 767px) {
      padding-left: 0px;
    }

    > p {
      color: var(--wh, #333);
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 124.5%; /* 22.41px */
      margin-bottom: 15px;
      margin-top: 0px;
      max-width: 400px;
    }
    > p sup {
      font-size: 10px;
      font-family: "Space Grotesk", sans-serif !important;
    }
  }
`;

const Pseclast = styled.div`
  color: var(--wh, #fff);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 124.5%; /* 22.41px */
  margin-bottom: 14px;
  max-width: 415px;
`;
const Lastptag2 = styled.div`
  color: var(--wh, #fff);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 124.5%; /* 22.41px */
  margin-bottom: 0px;
  max-width: 415px;
`;

const Lastptag = styled.div`
  color: var(--wh, #333);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 124.5%; /* 22.41px */
  margin-bottom: 0px;
  max-width: 400px;
`;
const DoNOTuse = styled.div`
  max-width: 1285px;
  margin: auto;
  display: flex;
  gap: 60px;
  justify-content: center;
  // padding: 0px 130px;
  padding: 0 15px;
  box-sizing: border-box;
  @media only screen and (max-width: 1300px) {
    gap: 30px;
  }
  @media only screen and (max-width: 1199px) {
    padding: 0px 35px;
    gap: 30px;
  }

  @media only screen and (max-width: 991px) {
    flex-direction: column;
    align-items: center;
    margin-top: -30px;
    padding: 0px 15px;
  }
`;
const Writingcomments = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 0px;

  @media only screen and (max-width: 767px) {
    margin-top: 40px;
    height: 100%;
  }
`;
const Imgcomments = styled.div`
  max-width: 1038px;
  // height: 142px;
  position: relative;
  border-radius: 65.5px;
  background: var(--blue, #0050b2);
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    padding: 10px 0px 20px 0px;
    border-radius: 15px;
  }
  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: row;
    padding: 13px;
    border-radius: 40px;
    min-height: 69px;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    > p {
      color: var(--w, #fff);
      font-family: Space Grotesk;
      font-size: 24px;
      font-style: normal;
      font-weight: bold;
      margin: 0px 0px;
      letter-spacing: -1.28px;
      padding: 0px 90px 0px 30px;

      @media only screen and (max-width: 1024px) {
        text-align: center;
        font-size: 18px;
        padding: 0px 73px 0px 48px;
      }
      @media only screen and (max-width: 767px) {
        font-size: 16px;
        padding: 5px 15px 5px 18px;
        text-align: left;
      }
      @media only screen and (max-width: 325px) {
        font-size: 16px;
        padding: 5px 5px 5px 5px;
        text-align: left;
      }

      > span {
        color: var(--ffffff, var(--w, #fff));
        font-family: Space Grotesk;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.72px;

        @media only screen and (max-width: 767px) {
          font-size: 10px;
          padding-right: 25px;
        }
        @media only screen and (max-width: 325px) {
          font-size: 8.5px;
          letter-spacing: -0.264px;
          font-weight: 300;
        }
      }
    }
  }
  svg {
    position: absolute;
    bottom: -21px;
    left: 18px;
    @media only screen and (min-width: 767px) {
      display: none;
    }
  }
`;
const Imageshape5 = styled.img.attrs({
  loading: "lazy",
})``;
const Imageshape4 = styled.img.attrs({
  loading: "lazy",
})`
     position: absolute;
    right: 52px;
    bottom: 66px;
    width: 20px;
    height: 20px;

  @media only screen and (max-width: 1024px) {
    // top: 168px;
    width: 18px;
    height: 18px;
  }
  @media only screen and (max-width: 767px) {
    right: 20px;
    bottom: 18px;
    width: 16px;
    height: 16px;
  }
  @media only screen and (max-width: 325px) {
    right: 26px;
    bottom: 13px;
    width: 15px;
    height: 15px;
  }
`;
const Imageshape3 = styled.img.attrs({
  loading: "lazy",
})`
      position: absolute;
    left: 90px;
    top: 10px;
    width: 20px;
    height: 20px;

  @media only screen and (max-width: 1024px) {
    left: 30px;
    top: 84px;
    width: 18px;
    height: 18px;
  }
  @media only screen and (max-width: 767px) {
    left: 64px;
    top: 10px;
    width: 16px;
    height: 16px;
  }
  @media only screen and (max-width: 325px) {
    left: 57px;
    top: 12px;
    width: 15px;
    height: 15px;
}
  }

  > span {
  }
`;
const Imageshape2 = styled.img.attrs({
  loading: "lazy",
})`
  width: 74px !important;
  height: 74px !important;
  margin-left: -5px;
  margin-top: -4px;
  padding: 13px;

  @media only screen and (max-width: 1024px) {
    margin-left: unset;
    padding-left: 2px;
    margin-left: unset;
  }
  @media only screen and (max-width: 767px) {
    width: 55px !important;
    height: 55px !important;
    position: relative;
    z-index: 1;
  }
`;
const Imageshape = styled.img.attrs({
  loading: "lazy",
})`
  // position: absolute;
  // z-index: 21;
  // top: 80px;
  // left: -110px;
  // right: 0;
  // margin: auto;
  // height: 120px;

  @media only screen and (max-width: 1499px) {
    // width: 180px;
    // height: 120px;
  }
  // @media only screen and (max-width: 1299px) {
  //   width: 130px;
  //   height: 100px;
  // }
  @media only screen and (max-width: 1199px) {
    // width: 120px;
    // height: 90px;
  }
  @media only screen and (max-width: 1024px) {
    // width: 100px;
    // height: 60px;
    // left: 0px;
  }
  // @media only screen and (max-width: 767px) {
  //   left: 0px;
  // }
  // @media only screen and (max-width: 600px) {
  //   left: 221px;
  // }
  // @media only screen and (max-width: 480px) {
  //   left: 28px;
  // }
  // @media only screen and (max-width: 320px) {
  //   left: 28px;
  //   top: 136px;
  // }

  > img {
    width: 180.829px;
    height: 153.804px;

    position: absolute;
    top: 40px;
    left: 331px;
  }
`;

const Commentrow = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 1299px) {
    gap: 15px;
  }

  @media only screen and (min-width: 767px) {
    padding-right: 20px;
    padding-left: 20px;

   
  }
     @media only screen and (max-width: 991px) {
     justify-content: center;
    flex-wrap: wrap;
  }
  @media only screen and (min-width: 1200px) {
    margin-top: 40px;
  }
`;

const Commentwraper = styled.div`
  > svg {
    margin-top: -24px;
  }
  @media only screen and (max-width: 1024px) {
    svg {
      display: none;
    }
  }
  @media only screen and (max-width: 767px) {
    gap: 15px;
  }
`;
const Boxcomment = styled.div`
  border-radius: 38px;
  // background: var(--blue, #0050b2);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  flex-direction: column;
  align-items: center;
  max-width: 343px;

  box-sizing: border-box;

  > p {
    color: var(--w, #222222);
    text-align: center;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 124.7%; /* 22.446px */
    padding: 0px 32px;

    @media only screen and (max-width: 1024px) {
    max-width: 685px;
    border-radius: 22.939px;

    > p {
    margin-top: 20px;
    }
  }
  }
  > p sup {
    font-size: 10px;
    font-family: "Space Grotesk", sans-serif !important;
  }

  @media only screen and (max-width: 1024px) {
    max-width: 685px;
    border-radius: 22.939px;

  }

  @media only screen and (min-width: 767px) {
    padding: 19px;
    min-height: 150px;
  }

  @media only screen and (max-width: 767px) {
   
    padding: 0px 15px;
    min-height: max-content;
  }
`;

const Sectioninnerouter = styled.div`

  background: #F7F7F7;
 padding: 10px 130px 35px;

  position: relative;

  @media only screen and (max-width: 1200px) {
    padding: 10px 15px;
  }
  @media only screen and (max-width: 1024px) {
    margin-top: 40px;
    border-radius: 15px 15px 0px 0px;
  }
`;
const Sectioninner = styled.div`
  max-width: 1250px;
  margin: auto;
`;

const Title = styled.div`
  > h2 {
    color: #222;
    text-align: center;
    font-family: Space Grotesk;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 134.1%; /* 48.276px */
    letter-spacing: -1.44px;

    @media only screen and (max-width: 767px) {
      font-size: 26px;
      text-transform: none;
    }
  }
  > h2 sup {
    font-size: 14px;
    font-family: "Space Grotesk", sans-serif !important;
  }
`;

const Btnalign = styled.main`
  text-align: center;
  margin: 20px auto 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const FaqinnerSection = styled.main`
  @media only screen and (max-width: 767px) {
    > div:nth-child(2).closed {
      max-height: 60px;
    }
    > div:nth-child(3).closed {
      max-height: 60px;
    }

    > div:nth-child(2).closed > div > div,
    > div:nth-child(3).closed > div > div {
      margin-top: 15px;
    }
  }
`;
const FaqinnerSection2 = styled.main`
  @media only screen and (max-width: 767px) {
    > div:nth-child(4).closed {
      max-height: 60px;
    }
    > div:nth-child(4).closed > div > div {
      margin-top: 15px;
    }
  }
`;

const Container = styled.main``;

const Cloudiv = styled.div`
  position: relative;
`;
const MobImg = styled.img.attrs({
  loading: "lazy",
})`
  display: none;
  @media only screen and (max-width: 1023px) {
    display: block;
  }
`;

const PlanboxTwo = styled.div`
  border-radius: 20px;
  ${"" /* max-width: 1353px; */}
  width:100%;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  background: #fff;
  margin-top: 70px;
  padding: 20px 10px;
  background: radial-gradient(
    96.02% 97.16% at 23.13% 29.46%,
    #0050b2 0%,
    #0a66c2 70.17%,
    #0050b2 100%
  );

  @media only screen and (max-width: 991px) {
    max-width: 100%;
    margin: auto;
    margin-top: 60px;
  }
  @media only screen and (max-width: 991px) {
    margin-top: 0px;
  }
`;
const ProPlan = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  text-align: center;
  font-family: Space Grotesk;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 164.523%; /* 59.228px */
  letter-spacing: -1.44px;
  color: #fff;

  @media only screen and (max-width: 767px) {
    font-size: 27px;
    line-height: 124.523%;
    padding: 0px;
  }
`;
const SvgImge = styled.div`
  position: absolute;
  right: 0;
  z-index: 0;
  left: 0;
  margin: auto;
  width: max-content;
  top: -58px;
`;

const Text = styled.div`
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 134.1%; /* 26.82px */
  letter-spacing: -0.8px;
  text-align: left;
  color: #fff;
  margin-left: 4px;

  @media only screen and (max-width: 767px) {
    font-size: 16px;
  }
`;

const CancleAnytime = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 18px;
`;
const TextCancel = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  > svg {
    @media only screen and (max-width: 767px) {
      width: 13px;
      height: 13px;
    }
  }
`;

const FAQSectionWrapper = styled.div`
  margin-top: 1.6rem;
  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 1023px) {
    max-width: 680px;
    margin: auto;
    margin-top: 25px;
  }
`;



const LogoImg = styled.img.attrs({
  loading: "lazy",
})`
  width: 4rem;
`;

// comenting section css
const Commenting = styled.div`
    padding: 35px 15px;
    max-width: 1295px;
    margin: auto;
    box-sizing: border-box;


>h2{
    font-family: Space Grotesk;
    font-size: 36px;
    font-weight: 700;
    line-height: 48.28px;
    letter-spacing: -0.04em;
    text-align: center;
    color: #222222;
    margin:0px;
    margin-bottom:50px;
    @media only screen and (max-width:1024px){
    margin-bottom:30px;
    }
    @media only screen and (max-width:1024px){
      font-size: 28px;
      line-height: 38px;
    }
}




`;
const CommentingMain = styled.div`
     display: flex;
    justify-content: space-between;
    gap: 50px;

    @media only screen and (max-width:1023px){
      flex-direction:column;
      gap: 30px;
    }
  
`;
const ComentingLeft = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width:385px;
    width: 100%;

    @media only screen and (max-width:1200px){
      max-width:330px;
     }
     @media only screen and (max-width:1023px){
      max-width:100%;
     }
    
`;
const ComentingBox = styled.div`
    position:relative;
    padding-left: 110px;

    @media only screen and (max-width:1023px){
      padding-left: 60px;
    }
>h3{
  font-family: Space Grotesk;
    font-size: 28px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #181818;
    margin: 0px;
    @media only screen and (max-width:1023px){
      font-size: 24px;
    }
}
>p{
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.5px;
  text-align: left;
  margin:0;
  margin-top:15px;
  color: #181818;
  max-width: 270px;
  @media only screen and (max-width:1023px){
      max-width: 100%;
      width: 100%;
      font-size: 16px;
     }
     

}
`;
const ComentingRight = styled.div`
  width:100%;
  display:flex;
  gap:22px;
  flex-wrap:wrap;
  max-width: 770px;
  margin: auto;
  padding-right: 15px;

  @media only screen and (max-width:1023px){
    padding: 0 16px;
    box-sizing: border-box;
  }
  
`;
const ComentingImagewrape = styled.img`
    width: 92px;
    height: 92px;
    position: absolute;
    left: 0;
    top: 0;
    @media only screen and (max-width:1023px){
      width: 50px;
      height: 50px;
     }
  `
const Commentingafter = styled.div`
    display:flex;
    flex-direction:column;
    gap:23px;
    position:relative;
    max-width: 275px;
    width: 100%;
    height: max-content;
    @media only screen and (max-width:767px){
      max-width: 100%;
     }
`;
const CommentingDiv = styled.div`
    background: #F3F3F3;
    border-radius: 10px;
    padding: 5px 13px 13px;
    max-width: 270px;
    box-sizing: border-box;
    @media only screen and (max-width:767px){
      max-width: 100%;
     }
>h4{
    font-family: Space Grotesk;
    font-size: 12px;
    font-weight: 700;
    line-height: 15.31px;
    text-align: left;
    margin: 0;
    margin-bottom: 10px;
    color:#333333;
}
`;
const Blutexth4 = styled.div`
margin-bottom:10px;
  >h4{
    font-family: Space Grotesk;
    font-size: 12px;
    font-weight: 700;
    line-height: 15.31px;
    text-align: left;
    margin: 0;
    margin-bottom: 10px;
    color:#0050B2;
    margin:0px;
}
`;
const ComentingImagebefore = styled.img`
     width: calc(100% + 30px);
     margin-left: -15px;
     position: absolute;
     top:0;
     left:0px;
     right:0px;
     bottom:0px;
     height:100%;
`;
const ComentingImagebefore1 = styled.img`
  width:100%;
  width:23px;
  height:60px;
  position: absolute;
  left: -16px;
  bottom: 27px;
  top: 0;
  margin: auto;
`;
const ComentingImagebefore2 = styled.img`
  width:100%;
  width:23px;
  height:60px;
  position: absolute;
  right: -16px;
  bottom: 27px;
  top: 0;
  margin: auto;
`;
const ComentDicmainimg = styled.div`
     position:relative;
     height:0px;
    padding-bottom: 40.5%;
`;
const CommentEndafter = styled.div`
    height:0px;
    padding-bottom: 15%;
    position:relative;
    margin:auto;
     `;
const ImgeComntendbef = styled.div``;
const Commntrghtwrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 22px;
    @media only screen and (max-width:767px){
      flex-direction: column;
     }
    `;
const ComentingImagebefore3 = styled.img`
    width:100%;
    height:100%;
    positon:absolute;
    top: 0;
    bottom:0;
    right:0px;
    left:0px;
    border-radius:10px;
    overflow:clip;
    box-shadow: 0px 4px 15px 0px #00000026;
`;

export default HomeNew;
