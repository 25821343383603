const TermsPage = () => {
    return(
        <main className="main-container terms">
            <h1>Terms</h1>
            <h2>Introduction</h2>
            <p>Welcome to Commenter.ai (“Company”, “we”, “our”, “us”)!</p>
            <p>These Terms of Service (“Terms”, “Terms of Service”) govern your use of our web pages located at <a href="https://commenter.ai">https://commenter.ai</a> operated by Commenter AI  LTD. Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that results from your use of our web pages. Please read it here <a href="/privacy" target="_blank">https://commenter.ai/privacy</a>.</p>
            <p>Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that you have read and understood the Agreements, and agree to be bound of them.</p>
            <p>If you do not agree with (or cannot comply with) Agreements, then you may not use the Service, but please let us know by emailing at support@commenter.ai so we can try to find a solution. These Terms apply to all visitors, users and others who wish to access or use the Service. Thank you for being responsible.</p>
            <h2>Communications</h2>
            <p>By creating an Account on our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at <a href="mailto:support@commenter.ai">support@commenter.ai</a>.</p>
            <h2>Purchases</h2>
            <p>If you wish to purchase any product or service made available through Service (“Purchase”), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.</p>
            <p>You represent and warrant that: <br/>(i) you have the legal right to use any credit card(s) or other payment methods (s) in connection with any Purchase; and that <br/>(ii) the information you supply to us is true, correct and complete.</p>
            <p>We may employ the use of third-party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to these third parties subject to our Privacy Policy.</p>
            <p>We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order, or other reasons.</p>
            <p>We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.</p>
            <p>We reserve the right to refuse or cancel your order at any time if we consider you have attempted to abuse our system. Such abuse includes, but is not limited to: starting multiple free trials, attempting to obtain several or unreasonable discounts on the product, and overall failure to pay for our product and services.</p>
            <h2>Subscriptions</h2>
            <p>Some parts of the Service are billed on a subscription basis (“Subscription(s)”). You will be billed in advance on a recurring and periodic basis (“Billing Cycle”). Billing cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.</p>
            <p>At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or Commenter AI  LTD cancels it. You may cancel your Subscription renewal either through your online account management page or by contacting the Commenter.ai customer support team.</p>
            <p>A valid payment method, including a credit card, is required to process the payment for your subscription. You shall provide Commenter AI  LTD with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize Commenter AI  LTD to charge all Subscription fees incurred through your account to any such payment instruments.</p>
            <p>Should automatic billing fail to occur for any reason, Commenter AI  LTD will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.</p>
            <p>If you are eligible to a discount, know that you cannot use more than 1 (one) discount per subscription.</p>
            <h3>Subscription cancellations</h3>
            <p>You may cancel Your Subscription renewal either through Your Account settings page or by contacting the Company. Upon cancellation, you will not receive a refund for the fees already paid for the current Subscription period. However, you will retain access to the Service until the end of your current Subscription period.</p>
            <p><strong>Effect of Cancellation on the Renewal Date:</strong> If you cancel your Subscription on the renewal date, the following conditions will apply:</p>
            <p><strong>1. Loss of Commenting Credits:</strong> Upon cancellation, all unused commenting credits will be lost, and you will no longer be able to generate comments using our Service.</p>
            <p><strong>2. Data Deletion:</strong> All saved data associated with your account, including but not limited to comments, drafts, and lists, will be permanently deleted. This data will not be recoverable once the cancellation is processed.</p>
            <p><strong>Early Cancellation:</strong> You may choose to cancel your subscription early at your convenience; however, we will not provide any refunds of prepaid fees or unused Subscription Fees. You will retain access to the Service until the end of the current Subscription period despite the cancellation.</p>
            <h2>Free Trial</h2>
            <p>Commenter AI  LTD may, at its sole discretion, offer a Subscription with a free trial for a limited period of time (“Free Trial”).</p>
            <p>You may be required to enter your billing information in order to sign up for a Free Trial.</p>
            <p>If you do enter your billing information when signing up for Free Trial, you will NOT be charged by Commenter AI  LTD until Free Trial has expired. On the last day of the Free Trial period, unless you cancel your Subscription, you will be automatically charged the applicable Subscription fees for the type of Subscription you have selected. At any time and without notice, Commenter AI  LTD reserves the right to (i) modify the Terms of Service of the Free Trial offer, or (ii) cancel such Free Trial offer.</p>
            <h2>Fee Changes</h2>
            <p>Commenter AI  LTD, in its sole discretion and at any time, may modify Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.</p>
            <p>Commenter AI  LTD will provide you with reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.</p>
            <p>Your continued use of the Service after the Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.</p>
            <h2>Refunds</h2>
            <p>We issue refunds for Contracts within thirty (30) days of the original purchase of the Contract. To obtain a refund, you can contact us by email at <a href="mailto:support@commenter.ai">support@commenter.ai</a>.</p>
            {/* <h3>Double-Money Back Guarantee Policy</h3>
            <p>Commenter.ai is committed to providing value to our customers. If you feel you haven't achieved the results you were expecting, we offer a double-money back guarantee under the following conditions:</p>
            <p>- Usage of Comments: You must have used all 3,000 comments allocated by the tool within the duration of your Contract.</p>
            <p>- Baseline Documentation: Prior to using Commenter.ai, customers seeking a refund must provide clear evidence of their social media impressions, followers count, and engagement rates. This acts as a baseline to assess performance improvements.</p>
            <p>- Post-Usage Documentation: After fully utilizing our tool, detailed evidence showcasing updated impressions, followers, and engagement rates is required.</p>
            <p>- Tool Integration Evidence: Clear documentation or screenshots showing how Commenter.ai was consistently integrated and used in your social media activities are necessary.</p>
            <p>To request a refund based on the above criteria, contact our support team within thirty (30) days of your original purchase at <a href="mailto:support@commenter.ai">support@commenter.ai</a>. Refund requests that do not meet these stipulated conditions will not be honored</p> */}
            <h2>Content</h2>
            <p>Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material (“Content”). You are responsible for Content that you post on or through Service, including its legality, reliability, and appropriateness.</p>
            <p>By posting Content on or through the Service, You represent and warrant that: (i) Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and (ii) that the posting of your Content on or through Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.</p>
            <p>You retain any and all of your rights to any Content you submit, post or display on or through Service and you are responsible for protecting those rights. We take no responsibility and assume no liability for the Content you or any third party posts on or through Service. However, by posting Content using Service you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through Service. You agree that this license includes the right for us to make your Content available to other users of Service, who may also use your Content subject to these Terms.</p>
            <p>Commenter AI  LTD has the right but not the obligation to monitor and edit all Content provided by users.</p>
            <p>Users of Commenter.ai may also use our artificial intelligence technology or copywriting services to obtain new Content. If this is your case, You are responsible for using this Content and will be responsible that this content does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity.</p>
            <p>In certain use cases, our artificial intelligence may create content that is deemed inappropriate or offensive. Commenter AI  LTD will not be held legally responsible if that happens, as the artificial intelligence is unbiased and will only create content it believes to have a high chance of performing well on social media, based on past performance of similar posts.</p>
            <h2>Prohibited Uses</h2>
            <p>
                You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:<br/>
                (a) In any way that violates any applicable national or international law or regulation.<br/>
                (b) For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.<br/>
                (c) To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.<br/>
                (d) To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.<br/>
                (e) In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.<br/>
                (f) To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or which, as determined by us, may harm or offend Company or users of Service or expose them to liability.<br/>
                (g) In any way that infringes on LinkedIn’s Terms of Service (available at <a href="https://www.linkedin.com/legal/user-agreement" target="_blank" rel="noreferrer">https://www.linkedin.com/legal/user-agreement</a>). Should you infringe on LinkedIn’s Terms of Service by using Commenter.au, you will be solely responsible should your LinkedIn account be subject to any action undertaken by LinkedIn. We also reserve the right to close your Commenter.ai account if we recognize such prohibited activity, in order to preserve the security of the other users.
            </p>
            <h3>Additionally, you agree not to:</h3>
            <p>
            (a) Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party’s use of Service, including their ability to engage in real-time activities through Service.<br/>
            (b) Use any robot, spider, or other automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.<br/>
            (c) Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.<br/>
            (d) Use any device, software, or routine that interferes with the proper working of Service.<br/>
            (e) Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.<br/>
            (f) Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.<br/>
            (g) Attack Service via a denial-of-service attack or a distributed denial-of-service attack.<br/>
            (h) Take any action that may damage or falsify Company rating.<br/>
            (i) Otherwise attempt to interfere with the proper working of Service.
            </p>
            <h2>Data Loss</h2>
            <p>The Company does not accept responsibility for the security of Your account or content. You agree that Your use of the Website or Services is at Your own risk.</p>
            <h2>Indemnification</h2>
            <p>You agree to defend and indemnify the Company and any of its affiliates (if applicable) and hold Us harmless against any and all legal claims and demands, including reasonable attorney’s fees, which may arise from or relate to Your use or misuse of the Website or Services, Your breach of this Agreement, or Your conduct or actions. You agree that the Company shall be able to select its own legal counsel and may participate in its own defense, if the Company wishes.</p>
            <h2>SPAM Policy</h2>
            <p>You are strictly prohibited from using the Website or any of the Company’s Services for illegal spam activities, including gathering email addresses and personal information from others or sending any mass commercial emails.</p>
            <h2>Analytics</h2>
            <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
            <h4>Google Analytics</h4>
            <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: <a href="https://policies.google.com/privacy?hl=enWe" target="_blank" rel="noreferrer">https://policies.google.com/privacy?hl=enWe</a> also encourage you to review the Google's policy for safeguarding your data: <br/><a href="https://support.google.com/analytics/answer/6004245" target="_blank" rel="noreferrer">https://support.google.com/analytics/answer/6004245</a>.</p>
            <h4>Firebase</h4>
            <p>Firebase is analytics service provided by Google Inc.</p>
            <p>You may opt-out of certain Firebase features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy: <a href="https://policies.google.com/privacy?hl=enFor" target="_blank" rel="noreferrer">https://policies.google.com/privacy?hl=enFor</a> more information on what type of information Firebase collects, please visit the Google Privacy Terms web page: <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer">https://policies.google.com/privacy?hl=en</a></p>
            <h4>Mixpanel</h4>
            <p>Mixpanel is provided by Mixpanel Inc.</p>
            <p>You can prevent Mixpanel from using your information for analytics purposes by opting-out. To opt-out of Mixpanel service, please visit this page: <a href="https://mixpanel.com/optout/" target="_blank" rel="noreferrer">https://mixpanel.com/optout/</a></p>
            <p>For more information on what type of information Mixpanel collects, please visit the Terms of Use page of Mixpanel: <a href="https://mixpanel.com/terms/" target="_blank" rel="noreferrer">https://mixpanel.com/terms/</a></p>
            <h4>Amplitude</h4>
            <p>Amplitude is provided by Amplitude Inc.</p>
            <p>Amplitude anonymously monitors your usage of Commenter.ai. It helps our team understand what features are most used without having any personal data about our users. Currently, you may not opt out of tracking by amplitude. For more information on what data is collected, you may visit <a href="https://amplitude.com/privacy" target="_blank" rel="noreferrer">https://amplitude.com/privacy</a></p>
            <h2>No Use By Minors</h2>
            <p>Service is intended only for access and use by individuals at least eighteen (18) years old. By accessing or using any of the Company, you warrant and represent that you are at least eighteen (18) years of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of the Terms. If you are not at least eighteen (18) years old, you are prohibited from both access and usage of the Service.</p>
            <h2>Accounts</h2>
            <p>When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on Service.</p>
            <p>You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
            <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.</p>
            <p>We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.</p>
            <h2>Intellectual Property</h2>
            <p>Service and its original content (excluding Content provided by users or by our Content generation features), features and functionality are and will remain the exclusive property of Commenter AI  LTD and its licensors. Service is protected by copyright, trademark, and other laws of foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Commenter AI  LTD.</p>
            <h2>Error Reporting and Feedback</h2>
            <p>You may provide us directly at <a href="mailto:support@commenter.ai">support@commenter.ai</a> with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service (“Feedback”). You acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback; (ii) Company may have development ideas similar to the Feedback; (iii) Feedback does not contain confidential information or proprietary information from you or any third party; and (iv) Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.</p>
            <h2>Links To Other Web Sites</h2>
            <p>Our Service may contain links to third-party websites or services that are not owned or controlled by Commenter AI  LTD.</p>
            <p>Commenter AI  LTD has no control over and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</p>
            <p>YOU ACKNOWLEDGE AND AGREE THAT Commenter AI  LTD SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES. WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD-PARTY WEBSITES OR SERVICES THAT YOU VISIT.</p>
            <h2>Disclaimer Of Warranty</h2>
            <p>THESE SERVICES ARE PROVIDED BY THE COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.</p>
            <p>NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS.</p>
            <h2>Modification & Variation</h2>
            <p>The Company may, from time to time and at any time without notice to You, modify this Agreement. You agree that the Company has the right to modify this Agreement or revise anything contained herein. You further agree that all modifications to this Agreement are in full force and effect immediately upon posting on the Website and that modifications or variations will replace any prior version of this Agreement, unless prior versions are specifically referred to or incorporated into the latest modification or variation of this Agreement.</p>
            <p>To the extent any part or sub-part of this Agreement is held ineffective or invalid by any court of law, You agree that the prior, effective version of this Agreement shall be considered enforceable and valid to the fullest extent.</p>
            <p>You agree to routinely monitor this Agreement and refer to the Effective Date posted at the top of this Agreement to note modifications or variations. You further agree to clear Your cache when doing so to avoid accessing a prior version of this Agreement. You agree that Your continued use of the Website after any modifications to this Agreement is a manifestation of Your continued assent to this Agreement.</p>
            <p>In the event that You fail to monitor any modifications to or variations of this Agreement, You agree that such failure shall be considered an affirmative waiver of Your right to review the modified Agreement.</p>
            <h2>Entire Agreement</h2>
            <p>This Agreement constitutes the entire understanding between the Parties with respect to any and all use of this Website. This Agreement supersedes and replaces all prior or contemporaneous agreements or understandings, written or oral, regarding the use of this Website.</p>
            <h2>Service Interruptions</h2>
            <p>The Company may need to interrupt Your access to the Website to perform maintenance or emergency services on a scheduled or unscheduled basis. You agree that Your access to the Website may be affected by unanticipated or unscheduled downtime, for any reason, but that the Company shall have no liability for any damage or loss caused as a result of such downtime.</p>
            <h2>Term, Termination & Suspension</h2>
            <p>The Company may terminate this Agreement with You at any time for any reason, with or without cause. The Company specifically reserves the right to terminate this Agreement if You violate any of the terms outlined herein, including, but not limited to, violating the intellectual property rights of the Company or a third party, failing to comply with applicable laws or other legal obligations, and/or publishing or distributing illegal material. If You have registered for an account with Us, You may also terminate this Agreement at any time by contacting Us and requesting termination. At the termination of this Agreement, any provisions that would be expected to survive termination by their nature shall remain in full force and effect.</p>
            <h2>Limitation on liability</h2>
            <p>The Company is not liable for any damages that may occur to You as a result of Your use of the Website or Services, to the fullest extent permitted by law. The maximum liability of the Company arising from or relating to this Agreement is limited to the greater of fifty ($50) US Dollars or the amount You paid to the Company in the last three (3) months. This section applies to any and all claims by You, including, but not limited to, lost profits or revenues, consequential or punitive damages, negligence, strict liability, fraud, or torts of any kind.</p>
            <h2>General Provisions</h2>
            <p>LANGUAGE: All communications made or notices given pursuant to this Agreement shall be in the English language.</p>
            <p>JURISDICTION, VENUE & CHOICE OF LAW: Through Your use of the Website or Services, You agree that the laws of Bulgaria shall govern any matter or dispute relating to or arising out of this Agreement, as well as any dispute of any kind that may arise between You and the Company, with the exception of its conflict of law provisions. In case any litigation specifically permitted under this Agreement is initiated, the Parties agree to submit to the personal jurisdiction of Bulgaria. The Parties agree that this choice of law, venue, and jurisdiction provision is not permissive, but rather mandatory in nature. You hereby waive the right to any objection of venue, including assertion of the doctrine of forum non conveniens or similar doctrine.</p>
            <p>ARBITRATION: In case of a dispute between the Parties relating to or arising out of this Agreement, the Parties shall first attempt to resolve the dispute personally and in good faith. If these personal resolution attempts fail, the Parties shall then submit the dispute to binding arbitration. The arbitration shall be conducted in Bulgaria. The arbitration shall be conducted by a single arbitrator and such arbitrator shall have no authority to add Parties, vary the provisions of this Agreement, award punitive damages, or certify a class. The arbitrator shall be bound by the applicable and governing law of Bulgaria. Each Party shall pay their own costs and fees. Claims necessitating arbitration under this section include, but are not limited to: contract claims, tort claims based on local laws, ordinances, statutes or regulations. Intellectual property claims by the Company will not be subject to arbitration and may, as an exception to this sub-part, be litigated. The Parties, in agreement with this sub-part of this Agreement, waive any rights they may have to a jury trial in regard to arbitral claims.</p>
            <p>ASSIGNMENT: This Agreement, or the rights granted hereunder, may not be assigned, sold, leased or otherwise transferred in whole or part by You. Should this Agreement, or the rights granted hereunder, by assigned, sold, leased or otherwise transferred by the Company, the rights and liabilities of the Company will bind and inure to any assignees, administrators, successors and executors.</p>
            <p>SEVERABILITY: If any part or sub-part of this Agreement is held invalid or unenforceable by a court of law or competent arbitrator, the remaining parts and sub-parts will be enforced to the maximum extent possible. In such condition, the remainder of this Agreement shall continue in full force.</p>
            <p>NO WAIVER: In the event that We fail to enforce any provision of this Agreement, this shall not constitute a waiver of any future enforcement of that provision or of any other provision. Waiver of any part or sub-part of this Agreement will not constitute a waiver of any other part or sub-part.</p>
            <p>HEADINGS FOR CONVENIENCE ONLY: Headings of parts and sub-parts under this Agreement are for convenience and organization, only. Headings shall not affect the meaning of any provisions of this Agreement.</p>
            <p>NO AGENCY, PARTNERSHIP OR JOINT VENTURE: No agency, partnership, or joint venture has been created between the Parties as a result of this Agreement. No Party has any authority to bind the other to third parties.</p>
            <p>FORCE MAJEURE: The Company is not liable for any failure to perform due to causes beyond its reasonable control including, but not limited to, acts of God, acts of civil authorities, acts of military authorities, riots, embargoes, acts of nature and natural disasters, and other acts which may be due to unforeseen circumstances.</p>
            <p>ELECTRONIC COMMUNICATIONS PERMITTED: Electronic communications are permitted to both Parties under this Agreement, including e-mail or fax. For any questions or concerns, please email Us at the following address: <a href="mailto:support@commenter.ai">support@commenter.ai</a>.</p>
        </main>
    )
}

export default TermsPage;
