import styled from "styled-components";
import { size } from "../utils/helpers";
import React, { useEffect, useState } from "react";
import topLeft from "../assets/clip-top-left.png";
import bottomRight from "../assets/clip-bottom.png";
import { validateSession } from "../hooks/useValidateSession";
import { useNavigate } from "react-router-dom";
import { fetchAPI } from "../utils/fetch";
import { config } from "../utils/config";
import { FaSearch } from "react-icons/fa";
import { SavedComment } from "../components/savedComment";
import UnlockAllFeaturesModal from "../components/UnlockAllFeatures";

const MyCommentList = () => {
  const navigate = useNavigate();
  const storage = localStorage.getItem("userData");
  const storageJson = JSON.parse(storage);
  const [size, setSize] = useState(5);
  const [list, setList] = useState([]);
  const [openModal, setOpenModal] = useState(true);

  const generateTable = (list) => {
    let temp = list ? [...list] : [];
    for (let i = 0; i < size - list.length; i++) temp.push({});
    return temp.slice(0, size);
  };

  const deleteComment = (hash) => {
    fetchAPI
      .post(
        `${config.urlPlatform}/api/v2/comments/remove-comment`,
        {
          withCredentials: true,
        },
        {
          email: storageJson.email,
          hash,
        }
      )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("error");
        }
        let temp = storageJson.savedComments.filter(
          (item) => item.id != parseInt(hash)
        );
        const newlist = generateTable(temp);
        setList([...newlist]);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    validateSession()
      .then((result) => {
        if (result.success === false || result === false) {
          //navigate("/unauthorized");
        }
      })
      .catch((error) => {
        //navigate("/unauthorized");
      });

    fetchAPI
      .get(
        `${config.urlPlatform}/api/v2/auth/user?email=${storageJson.email}`,
        {
          withCredentials: true,
        }
      )
      .then((result) => {
        if (result.status === 200) {
          delete result.data.subscriptionData;
          localStorage.setItem("userData", JSON.stringify(result.data));
          setList(generateTable(result.data.savedComments));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    setList(generateTable(list));
  }, [size]);

  const searchComment = (e) => {
    let temp = [];
    if (e.target.value)
      temp = storageJson.savedComments.filter(
        (item) => item?.comment?.indexOf(e.target.value) > -1
      );
    else temp = storageJson.savedComments;
    setList(generateTable(temp));
  };
  const loadMore = () => {
    setSize(size + 5);
  };

  return (
    <SectionContainer>
      <FigureLeft>
        <FigureImage src={topLeft} />
      </FigureLeft>
      <FigureRight>
        <FigureImage src={bottomRight} />
      </FigureRight>
      <EngagementSection>
        <EngagementTitle>My saved comments</EngagementTitle>
        <div
          style={{
            maxWidth: 1000,
            textAlign: "right",
            alignSelf: "center",
            width: "100%",
          }}
        >
          <a
            style={{ color: "white", fontSize: 20 }}
            href="https://youtu.be/_i3aeuhLfQU"
            target="_blank"
          >
            How to use this Feature?
          </a>
        </div>
        <TableSection>
          <FilterBar>
            <Search>
              <FaSearch
                style={{
                  height: "20px",
                  width: "20px",
                  padding: "4px",
                  position: "absolute",
                  boxSizing: "border-box",
                  top: "50%",
                  right: "2px",
                  transform: "translateY(-50%)",
                }}
              />
              <InputSearch onChange={searchComment} placeholder="Search" />
            </Search>
          </FilterBar>
          <CommentSection>
            {list?.map((item, idx) => (
              <SavedComment
                key={idx}
                isLast={idx === list.length - 1 ? true : false}
                comment={item}
                removeComment={deleteComment}
              />
            ))}
            {storageJson?.savedComments?.length > list.length && (
              <LoadMore>
                <LoadMoreBtn onClick={loadMore}>See more comments</LoadMoreBtn>
              </LoadMore>
            )}
          </CommentSection>
        </TableSection>
      </EngagementSection>
      {openModal && (
        <UnlockAllFeaturesModal
          setOpenModal={setOpenModal}
          title="Unlock all the features"
          functions={[
            "3000 comments per month",
            "6 commenting styles",
            "Access to our exclusive community",
            "Monthly AMAs with our co-founder",
            "Special discounts for events, cources",
            "Personalized commenting strategy",
            "30% referral commission",
            "saving comments - Unlimited",
            "Engagement list - Unlimited",
          ]}
        />
      )}
    </SectionContainer>
  );
};

const SectionContainer = styled.section`
  position: relative;
  padding: 2%;
  min-height: 710px;
  background-color: #0050b2;
  font-family: Space Grotesk;
  > * {
    box-sizing: border-box;
  }
`;

const FigureLeft = styled.figure`
  position: absolute;
  top: 0;
  left: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  z-index: 1;
  > img {
    max-width: 400px;
  }
  @media only screen and (max-width: ${size.maxWidthTablet}) {
    > img {
      max-width: 300px;
    }
  }
  @media only screen and (max-width: ${size.minWidthTablet}) {
    > img {
      max-width: 150px;
    }
  }
`;

const FigureRight = styled.figure`
  position: absolute;
  bottom: -4px;
  right: 0;
  z-index: 1;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  > img {
    max-width: 400px;
  }
  @media only screen and (max-width: ${size.maxWidthTablet}) {
    > img {
      max-width: 300px;
    }
  }

  @media only screen and (max-width: ${size.minWidthTablet}) {
    > img {
      max-width: 150px;
    }
  }
`;
const FigureImage = styled.img``;

const EngagementSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
`;

const EngagementTitle = styled.h3`
  font-family: Space Grotesk;
  font-size: 48px;
  font-weight: 700;
  line-height: 61px;
  letter-spacing: 0em;
  width: 100%;
  text-align: center;
`;

const TableSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  @media only screen and (max-width: ${size.maxWidthTablet}) {
    max-width: 600px;
  }
  @media only screen and (max-width: ${size.minWidthTablet}) {
    max-width: 350px;
  }
`;

const FilterBar = styled.div`
  display: flex;
  justify-content: center;
  background: #05438e;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px 5px 0 0;
`;
const Search = styled.div`
  position: relative;
`;

const InputSearch = styled.input`
  color: #fff;
  font-family: Space Grotesk;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: transparent;
  border: none;
  border-bottom: 1px solid;
  outline: none;
  &::-webkit-input-placeholder {
    color: #fff;
  }
`;

const CommentSection = styled.div`
  display: flex;
  flex-direction: column;
  background: #353535;
  border-radius: 0 0 5px 5px;
`;

const LoadMore = styled.div`
  border-top: 0.5px solid rgba(255, 255, 255, 0.25);
  text-align: center;
  padding: 10px 0;
`;

const LoadMoreBtn = styled.span`
  text-decoration: underline;
  color: #fff;
  text-align: center;
  font-family: Space Grotesk;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
`;

export default MyCommentList;
